import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import allotIcon from "./Icons/allotIcon.svg";
import reciceveIcon from "./Icons/recieveIcon.svg";
import pgsIcon from "./Icons/pgs.svg";
import missIcon from "./Icons/missing.svg";
import "./home.css";
import HomeItems from "./HomeItems";
import { useApp } from "../../../Context/Context";
import { useNavigate } from "react-router-dom";
import AllotmentTable from "./AllotmentTable";
import WashableItemsTable from "./WashableItemsTable";

const Home = () => {
  const {  isAdmin ,getAllotmentHistory,isCleaner} = useApp();
  const navigate = useNavigate();
  useEffect(()=>{
    getAllotmentHistory();
  },[])

  const homeRoute = function(route){
    
    navigate(`/home${route}`)
  }

  let homeNavData1 = [
    {
      borderColor: "#98FF98",
      backgroundColor: "rgba(152, 255, 152, 0.6)",
      text: "Allot",
      textColor: "#21D50D",
      icon: allotIcon,
      route:"/allot-items"
    },
    {
      borderColor: "#ADD8E6",
      backgroundColor: "rgba(173, 216, 230, 0.6)",
      text: "Receive",
      textColor: "rgba(30, 81, 249, 1)",
      icon: reciceveIcon,
      route:"/receive-items"
    },
    {
      borderColor: "rgba(244, 194, 194, 1)",
      backgroundColor: "rgba(244, 194, 194, 0.6)",
      text: "Apartments",
      textColor: "rgba(255, 117, 117, 1)",
      icon: pgsIcon,
      route:"/apartments"
    },
    {
      borderColor: "#A9A9A9",
      backgroundColor: "#A9A9A999",
      text: "Defected / Missing Items",
      textColor: "#7D8698",
      icon: missIcon,
      route:"/defected-missing-items"
    }
  ];



  let homeNavData2 = [
    {
      borderColor: "rgba(244, 194, 194, 1)",
      backgroundColor: "rgba(244, 194, 194, 0.6)",
      text: "Apartments",
      textColor: "rgba(255, 117, 117, 1)",
      icon: pgsIcon,
      route:"/apartments"
    },
    
    {
      borderColor: "#ADD8E6",
      backgroundColor: "rgba(173, 216, 230, 0.6)",
      text: "Received Items",
      textColor: "rgba(30, 81, 249, 1)",
      icon: reciceveIcon,
      route:"/received-items"
    }
    
  ];
  
  return (
    <>
      {isAdmin  ? <Container fluid="xxl" className="homePageMainDiv">
        <div className="homePageNavigationMainDiv" >
          {homeNavData1.map((ele,i) => {
            return <div
              className="homePageNavDiv"
              style={{
                borderColor: `${ele.borderColor}`,
                backgroundColor: `${ele.backgroundColor}`,
              }}
              onClick={()=>homeRoute(ele.route)}
              key={i}
            >
              <img alt="homeNav" src={ele.icon} />
              <p style={{ color: `${ele.textColor}`}}>{ele.text}</p>
            </div>
          })}
          
        </div>
        <HomeItems />
      </Container> : <Container fluid="xxl" className="homePageMainDiv">
        <div className="homePageNavigationMainDiv" style={{border:"0px"}} >
          {homeNavData2.map((ele,i) => {
            return <div
              className="homePageNavDiv2"
              style={{
                borderColor: `${ele.borderColor}`,
                backgroundColor: `${ele.backgroundColor}`,
              }}
              onClick={()=>homeRoute(ele.route)}
              key={i}
            >
              <img alt="homeNav" src={ele.icon} />
              <p style={{ color: `${ele.textColor}`}}>{ele.text}</p>
            </div>
          })}
        </div>
      </Container>}

      {!isAdmin && !isCleaner && <AllotmentTable/>}
      {isCleaner && <WashableItemsTable/>}
    </>
  );
};

export default Home;
