import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const WashableItemsTable = () => {
  const [absentAllotments, setAbsentAllotments] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch absent allotments from localStorage
    const storedAbsentAllotments = localStorage.getItem("absent-allotments");
    if (storedAbsentAllotments) {
      setAbsentAllotments(JSON.parse(storedAbsentAllotments));
    }
  }, []);

  const handleRedirect = (id1,id2) => {
    navigate(`/home/apartments/${id1}/room-${id2}`);
  };

  return (
    <div>
      <h2>Allotments Alloted</h2>
      {absentAllotments.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Quantity</th>
              <th>Allotted At</th>
              <th>Apartment ID</th>
              <th>Room ID</th>
              <th>Item ID</th>
              <th>User ID</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {absentAllotments.map((allotment) => (
              <tr key={allotment.id}>
                <td>{allotment.id}</td>
                <td>{allotment.quantity}</td>
                <td>{new Date(allotment.alloted_at).toLocaleString()}</td>
                <td>{allotment.apartment_id}</td>
                <td>{allotment.room_id}</td>
                <td>{allotment.item_id}</td>
                <td>{allotment.user_id}</td>
                <td>
                  <button onClick={() => handleRedirect(allotment.apartment_id,allotment.room_id)}>
                    Redirect
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No absent allotments available.</p>
      )}
    </div>
  );
};

export default WashableItemsTable;
