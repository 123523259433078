import React, { useState } from "react";
import { useApp } from "../../../Context/Context";

const AddItemsModal = () => {
  // Define state for the inputs
  const { setModalActive, API_ENDPOINT, setItems, setAnimation } = useApp();
  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("No file chosen");
  const [itemType, setItemType] = useState("Washable"); // Default selection for item type

  // Handle file selection
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    } else {
      setFile(null);
      setFileName("No file chosen");
    }
  };

  // Handle form submission
  const handleSubmit = () => {
    setAnimation(true);
    const formData = new FormData();
    formData.append("item_name", name);
    formData.append("quantity", quantity);
    formData.append("item_photo", file);
    formData.append("item_type", itemType);

    console.log({
      name,
      quantity,
      file,
      itemType,
    });

    fetch(`${API_ENDPOINT}/owner/add_item`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);

        setItems((prevItems) => [
          ...prevItems,
          {
            item_id: data.item_id,
            item_name: data.item_name || name,
            quantity: data.quantity || quantity,
            item_photo: data.item_photo || file,
            item_type: data.item_type || itemType,
          },
        ]);

        // Close the modal after successful submission
        setModalActive(null);
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => setAnimation(false));
  };

  return (
    <>
      <div className="modalContentInner">
        <h3>Add Item</h3>
        <div className="inputContainer">
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="number"
            placeholder="Quantity"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
          <select
            value={itemType}
            onChange={(e) => setItemType(e.target.value)}
            className="modalSelect"
          >
            <option className="option" value="Washable">Washable</option>
            <option className="option" value="Permanent">Permanent</option>
            <option className="option" value="Regular">Regular</option>
          </select>
          <div className="file-input-container">
            <input
              type="file"
              id="file"
              className="file-input"
              onChange={handleFileChange}
            />
            <label htmlFor="file" className="file-label">
              Choose File
            </label>
            <span className="file-name">{fileName}</span>
          </div>
        </div>
        <button className="modalBtn" onClick={handleSubmit}>
          Add Item
        </button>
      </div>
    </>
  );
};

export default AddItemsModal;
