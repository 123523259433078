import React from "react";
import { useNavigate } from "react-router-dom";

const AllotmentTable = () => {
  // Fetch allotment history from localStorage
  const allotmentHistory = JSON.parse(localStorage.getItem("allotment-history")).allotment_history;

  const navigate = useNavigate();

  // Handle the Allot button click
  const handleAllot = (property, room) => {
    // home/apartments/1/room-1
    let userName = JSON.parse(sessionStorage.getItem("user-data")).user.name
    let propKey = property[`${userName}`][0]['apartment_details']['apartment_id']
    let roomKey = property[`${userName}`][0]['room_details']["room_id"]
    
    navigate(`/home/apartments/${propKey}/room-${roomKey}`);
  };

  return (
    <>
    {
    <div style={{ padding: "20px" }}>
      <h2>Allotment History</h2>
      <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}>
        <thead>
          <tr>
            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Property Name</th>
            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Room Name</th>
            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(allotmentHistory).map((propertyKey) =>
            Object.keys(allotmentHistory[propertyKey]).map((roomKey) => (
              <tr key={`${propertyKey}-${roomKey}`}>
                <td style={{ border: "1px solid #ccc", padding: "8px" }}>{propertyKey}</td>
                <td style={{ border: "1px solid #ccc", padding: "8px" }}>{roomKey}</td>
                <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                  <button
                    style={{
                      backgroundColor: "#007BFF",
                      color: "white",
                      border: "none",
                      padding: "8px 12px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleAllot(allotmentHistory[propertyKey][roomKey], roomKey)}
                  >
                    Allot
                  </button>
                </td>
              </tr>
              
            ))
          )}
        </tbody>
      </table>
    </div>}
    </>
  );
};

export default AllotmentTable;
