import React, { useEffect, useState } from "react";
import { useApp } from "../../../Context/Context";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";

const HeaderNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const [navLinks, setNavLinks] = useState([]);

  useEffect(() => {
    if (location?.pathname) {
      let pathArray = location.pathname.split('/').slice(1);
      console.log(pathArray);
      setNavLinks(pathArray)
    }
  }, [location]);


  const generateLink = function(items,ele){
    
    let str = "";
    for(let i = 0 ; i < items.length ; i++){
      if(items[i] === ele){
        str += items[i]
        break;
      }
      str += items[i]+'/'
    }

    return str
  }


  return (
    <Container fluid="xxl" className="headerNavigationMainDiv">
      <div className="headerNavigation">
        {navLinks &&
          navLinks.map((navigation, i) => (
            <React.Fragment key={i}>
              <Link
                to={`${generateLink(navLinks,navigation)}`}
                className="navigationLink"
              >
                <div className="headerNavBtn">
                {navigation}
                </div>
              </Link>
              {/* {i < navLinks.length - 1 && " / "} */}
            </React.Fragment>
          ))}
      </div>
    </Container>
  );
};

export default HeaderNavigation;
