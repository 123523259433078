import React, { useEffect, useState } from "react";
import axios from "axios";
import "./defected.css";
import { useApp } from "../../../Context/Context";

const DefectedMissingItems = () => {
  const { setAnimation, showAlert } = useApp();
  const [reportedItems, setReportedItems] = useState([]);

  const fetchDefectedMissingItems = async () => {
    setAnimation(true);
    try {
      const response = await axios.get(
        "https://teampavan.de/api/owner/get_missing_damaged_products"
      );
      // Reverse the array to show the latest first
      setReportedItems(response.data.reported_items.reverse());
      showAlert("success", "List fetched successfully!");
    } catch (error) {
      console.error("Error fetching defected and missing items:", error);
      showAlert("danger", "List fetch failed!");
    }
    setAnimation(false);
  };

  useEffect(() => {
    fetchDefectedMissingItems();
  }, []);

  const getRowClass = (status) => {
    if (status === "Damaged") return "damagedRow";
    if (status === "Missing") return "missingRow";
    return ""; // Default row class if status doesn't match
  };

  return (
    <div className="defectedTableContainer">
      {reportedItems.length > 0 ? (
        <table className="defectedTable">
          <thead>
            <tr>
              <th>Item Name</th>
              <th>Quantity</th>
              <th>Status</th>
              <th>Reported By</th>
              <th>Apartment</th>
              <th>Room</th>
              <th>Reported At</th>
            </tr>
          </thead>
          <tbody>
            {reportedItems.map((item, index) => (
              <tr key={index} className={getRowClass(item.status)}>
                <td>{item.item_name}</td>
                <td>{item.quantity}</td>
                <td>{item.status}</td>
                <td>{item.reported_by}</td>
                <td>{item.apartment}</td>
                <td>{item.room}</td>
                <td>{item.reported_at}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No defected or missing items reported.</p>
      )}
    </div>
  );
};

export default DefectedMissingItems;
