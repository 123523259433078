import React, { useState } from "react";
import imageLink from "./Icons/dummy.jpeg";
import checked from "../../CommonIcons/checked.svg";
import unckecked from "../../CommonIcons/unchecked.svg";
import { useApp } from "../../../Context/Context";

const SelectItemsCard = ({ items1, setItems1, item }) => {
  const { MEDIA_ENDPOINT } = useApp();

  const [inputValue, setInputValue] = useState(item.value || 1); // Local state for input value

  const handleCheckboxChange = (id) => {
    setItems1(
      items1.map((item) =>
        item.item_id === id ? { ...item, selected: !item.selected } : item
      )
    );
  };

  const handleBlur = (id) => {
    const parsedValue = parseInt(inputValue, 10);
    setItems1(
      items1.map((item) =>
        item.item_id === id
          ? {
              ...item,
              value:
                isNaN(parsedValue) || parsedValue <= 0
                  ? 1
                  : parsedValue > (item.quantity || item.quantity_allotted)
                  ? item.quantity || item.quantity_allotted
                  : parsedValue,
            }
          : item
      )
    );
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value); // Update local state
  };

  const isMobile = window.innerWidth <= 576;

  return (
    <>
      {isMobile ? (
          <li key={item.id} className="selectedMobItemMain cardMainDIv ">
            {/* <div className="toolTip">
              {item.item_type ? item.item_type : "Regular"}
            </div> */}
            

            <img
              alt="selectedItemPic"
              src={
                item.item_photo
                  ? `${MEDIA_ENDPOINT}${item.item_photo}`
                  : imageLink
              }
              onClick={() => {
                handleCheckboxChange(item.item_id);
              }}
            />
            <div className="itemsDetails">
              <p>
                {item.item_name}{" "}
                <span>({item.quantity || item.quantity_allotted})</span>
              </p>
              {item.selected && (
                <input
                  className="selectedItemInput"
                  type="number"
                  value={inputValue}
                  max={item.quantity || item.quantity_allotted}
                  min={1}
                  onChange={handleInputChange}
                  onBlur={() => handleBlur(item.item_id)} // Validate on blur
                />
              )}
            </div>
          </li>
      ) : (
        <div>
          <li key={item.id} className="cardMainDIv selectedItemMain">
            <div className="toolTip">
              {item.item_type ? item.item_type : "Regular"}
            </div>
            <div className="checkedUncked">
              {item.selected ? (
                <img alt="checked" src={checked} />
              ) : (
                <img alt="uncheked" src={unckecked} />
              )}
            </div>

            <img
              alt="selectedItemPic"
              src={
                item.item_photo
                  ? `${MEDIA_ENDPOINT}${item.item_photo}`
                  : imageLink
              }
              onClick={() => {
                handleCheckboxChange(item.item_id);
              }}
            />
            <div className="itemsDetails">
              <p>
                {item.item_name}{" "}
                <span>({item.quantity || item.quantity_allotted})</span>
              </p>
              {item.selected && (
                <input
                  className="selectedItemInput"
                  type="number"
                  value={inputValue}
                  max={item.quantity || item.quantity_allotted}
                  min={1}
                  onChange={handleInputChange}
                  onBlur={() => handleBlur(item.item_id)} // Validate on blur
                />
              )}
            </div>
          </li>
        </div>
      )}
    </>
  );
};

export default SelectItemsCard;
