import React from "react";
import arrow from "../../CommonIcons/itemArr.svg";
import addIcon from "../../CommonIcons/addIcon.svg";
import "../../commonCss/card.css";
import ApartmentCard from "./ApartmentCard";
import AddApartmentModal from "./AddApartmentModal";
import { useApp } from "../../../Context/Context";
import { useEffect } from "react";

const Apartments = () => {
  const { setModalActive, getAllottedApartments,fetchAllApartments, apartments,isAdmin,isCleaner } =
    useApp();
    // let userId = JSON.parse(sessionStorage.getItem("user-data")).user_id


  useEffect(() => {
    if(isAdmin || isCleaner)
      fetchAllApartments();
    else{
      getAllottedApartments()
    }

    // eslint-disable-next-line
  }, [isAdmin]);

  return (
    <>
      <div className=" majorDisplayMainDiv homeItemsMainDiv ">
        <h2>
         {!isAdmin && "Select"} Apartments <img alt="arrow" src={arrow} />
        </h2>
        <div className="cardsContiner">
          {isAdmin && <div
            className="addCards"
            onClick={() => {
              setModalActive(<AddApartmentModal />);
            }}
          >
            <img alt="addIcon" src={addIcon} style={{ margin: "0 0 10px 0" }} />
            <p>Add Apartment </p>
          </div>}
          <>
            {apartments.length > 0 &&
              apartments.map((apartment) => (
                <ApartmentCard apartment={apartment} key={apartment.apartment_id} />
              ))}
          </>
        </div>
      </div>
    </>
  );
};

export default Apartments;
