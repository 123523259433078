

import React, { useState } from "react";
import { useApp } from "../../../Context/Context";
import "../../commonCss/modal.css"

const AddRoomModal = ({apartment_id,rooms,setRooms}) => {
  // Define state for the inputs
  const {  setModalActive,API_ENDPOINT,setAnimation,showAlert } = useApp();
  const [roomName, setRoomName] = useState("");
  const [bedNumbers, setBedNumbers] = useState()


  // Handle form submission
  const handleSubmit = async() => {
    // Create a form data object if you want to send files as multipart/form-data
    setAnimation(true)
    const payload = {
      apartment: apartment_id,
      room_name: roomName,
      number_of_bed: bedNumbers,
    };
  
    try {
      // Make the POST request
      const response = await fetch(`${API_ENDPOINT}/owner/add_room`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload), // Convert payload object to JSON
      });
  
      if (response.ok) {
        const data = await response.json();
        setRooms([...rooms,data])
        showAlert("success", "Room Added Successfully !!")
        setModalActive(null)
        // Handle success (for example, update UI or navigate to a different page)
      } else {
        showAlert("danger", "Room Add failed !!")
        console.error('Error adding room:', response.statusText);
      }
    } catch (error) {
      console.error('Request failed:', error);
    }

    setAnimation(false)
   
  };

  return (
    <>
      <div className="modalContentInner">
        <h3>Add Rooms</h3>
        <div className="inputContainer">
          <input
            type="text"
            placeholder="Room Name"
            value={roomName}
            onChange={(e) => setRoomName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Number of Beds"
            value={bedNumbers}
            onChange={(e) => setBedNumbers(e.target.value)}
          />
        </div>
        <button className="modalBtn" onClick={handleSubmit}>
          Add
        </button>
      </div>
    </>
  );
};

export default AddRoomModal;
