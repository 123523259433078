import React, { useEffect, useState } from "react";
import { useApp } from "../../../Context/Context";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ItemCard from "../HomePage/ItemCard";

const ClenanerWorkFlow = () => {
  const { API_ENDPOINT ,getWashableAllotmentHistory} = useApp();
  const [apartment, setApartment] = useState("");
  const [roomName, setRoomName] = useState("");
  let { roomId, apartmentName } = useParams();
  roomId = roomId.split("-")[1];
  const [items1, setItems1] = useState([]);
  const navigate = useNavigate()
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    const flag = items1.some((item) => item.selected);
    setSelected(flag);
  }, [items1]);

  const fetchItemsFromLocalStorage = () => {
    // Retrieve all items from local storage
    const allAllotments = JSON.parse(
      localStorage.getItem("absent-allotments") || "[]"
    );

    // Filter items matching the current apartmentId and roomId
    const filteredItems = allAllotments.filter(
      (item) =>
        item.apartment_id === Number(apartmentName) &&
        item.room_id === Number(roomId)
    );

    // Map items to desired format and set state
    const mappingItems = filteredItems.map((item) => ({
      ...item,
      value: item.quantity_allotted,
      selected: false,
    }));

    setItems1(mappingItems);
    setApartment(apartmentName);
    setRoomName(`Room ${roomId}`);
  };

  
  const handleCollect = async () => {
   
  
    const userId = JSON.parse(sessionStorage.getItem("user-data")).user.user_id;
  
    try {
      // Loop through each selected item and hit the mark_item_present endpoint
      for (const item of items1) {
        const markItemPayload = { id: item.id };
        await axios.post(`${API_ENDPOINT}/cleaner/mark_item_present`, markItemPayload, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log(`Item ${item.item_id} marked as present`);
      }
  
      
      getWashableAllotmentHistory()
      fetchItemsFromLocalStorage();
      navigate('/home')
    } catch (error) {
      console.log("Error during item collection:", error);
    }
  };
  

  useEffect(() => {
    fetchItemsFromLocalStorage();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="ClenanerWorkFlowMainDiv">
        <span style={{ display: "flex", justifyContent: "space-between" }}>
          <h2>Items For Wash</h2>
          <div className="workerHeaderInfo">
            <ul>
              <li>
                Apartment Name - <span>{apartment}</span>
              </li>
              <li>
                Room Name - <span>{roomName}</span>
              </li>
            </ul>
          </div>
        </span>

        <div className="itemsContiner">
          {items1.map((item) => (
            <ItemCard editable={false} item={item} key={item.item_id} />
          ))}
        </div>

        <div className="BtnConatiner">
          <button
            className="blueBtn"
            onClick={() => {
              handleCollect();
            }}
          >
            Collect
          </button>
        </div>
      </div>
    </>
  );
};

export default ClenanerWorkFlow;
