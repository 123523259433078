import React, { useEffect, useState } from "react";
import arrow from "../../CommonIcons/itemArr.svg";
import { useApp } from "../../../Context/Context";
import ItemCard from "../HomePage/ItemCard";
import { useParams } from "react-router-dom";
import axios from "axios";
import SelectItemsCard from "../Worker/SelectItemsCard";

const ItemsRoomHave = () => {
  const { API_ENDPOINT,setAnimation,workerAlloted,fetchWorkers,workers,showAlert } = useApp();
  const [items, setItems] = useState([]);
  const [items1, setItems1] = useState([]);
  const [apartment, setApartment] = useState("");
  const [roomName, setRoomName] = useState("");
  const [workerUserID, setWorkerUserID] = useState(null);
  let { roomId, apartmentName } = useParams();
  const [selected, setSelected] = useState(false);
  

  useEffect(() => {
      const flag = items1.some((item) => item.selected);
      setSelected(flag)
    }, [items1]);
  const fetchItems = async () => {
    setAnimation(true)
    const url = `${API_ENDPOINT}/worker/get_items_allotted_to_room?apartment_id=${apartmentName}&room_id=${
      roomId.split("-")[1]
    }`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "User-id": JSON.parse(sessionStorage.getItem("user-data")).user_id,
        },
      });

      if (!response.ok) {
        const errData = await response.json();
        throw new Error(errData.error);
      }
      const data = await response.json();
      setItems(data.items);
      setApartment(data.apartment);
      setRoomName(data.room);
      console.log(data);
    } catch (err) {
      console.log(err); // Handle any errors
    }
    setAnimation(false)
  };
  console.log(workerAlloted)

  const fetchWorkerAllotedItems = async function () {
    setAnimation(true)
    let userId = workerUserID;
    try {
      if (!userId) {
        console.log("User Not Login");
      }
      const response = await axios.get(
        `${API_ENDPOINT}/worker/get_items_allotted_to_user`,
        {
          headers: {
            "User-id": userId, // Pass the user ID in the request header
          },
        }
      );
      console.log(response.data.allotted_items);
      let allotedItems = response.data.allotted_items;
      // setItems(data.items);
      if (allotedItems) {
        let mappingItems = [];

        allotedItems.forEach((item) => {
          let item1 = {
            ...item,
            value: 0,
            selected: false,
          };
          if (item1.quantity > 0) {
            mappingItems.push(item1);
          }
        });
        setItems1(mappingItems);
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        console.log("User not found.");
      } else {
        console.log("An error occurred while fetching the items.", err);
      }
    }
    setAnimation(false)
  };

   const handleAllot = async () => {
    setAnimation(true)
    let selectedItems = items1
      .filter((item) => item.selected === true)
      .map((item) => ({
        item_id: item.item_id,
        quantity: Number(item.value),
      }));
      console.log(roomId.split('-')[1])
    const payload = {
      apartment_id: Number(apartmentName),
      room_id: Number(roomId.split('-')[1]),
      items: selectedItems,
    };
    let userId = JSON.parse(sessionStorage.getItem("user-data")).user.user_id;
    console.log("userss",userId)
    try {
      const response = await axios.post(
        `${API_ENDPOINT}/worker/allot_items_to_room`,
        payload,
        {
          headers: {
            "User-id": userId,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Success:", response.data);
      if (response.status === 200) {
        // await fetchItems1();
      }
      fetchItems();
      fetchWorkerAllotedItems()
      showAlert("success", "Items Alloted to room Successfully !!")
    } catch (error) {
      console.log("Error allotting items to worker:", error);
      showAlert("danger", "Items Alloted to room failed !!")
      // Add any error handling or UI updates here
    }

   setAnimation(false)
  };

  useEffect(() => {
    fetchItems();
    fetchWorkers();
    

    // eslint-disable-next-line
  }, []);

  useEffect(()=>{
    if(workerUserID)
    {fetchWorkerAllotedItems();}
  },[workerUserID])

  useEffect(()=>{
    let user = workers.find(worker => worker.id == workerAlloted);
    console.log(user)
    if(user && user.user_id){
      setWorkerUserID(user.user_id)
    }

  },[workers])

 
  return (
    <>
      <div className="ItemsRoomHaveMainDiv">
        <span style={{ display: "flex", justifyContent: "space-between" }}>
          <h2>
            Items Have
            <img alt="arrow" src={arrow} />
          </h2>
          <div className="workerHeaderInfo">
            <ul>
              <li>
                Apartment Name - <span>{apartment}</span>
              </li>
              <li>
                Room Name - <span>{roomName}</span>
              </li>
            </ul>
          </div>
        </span>
        <div className="itemsContiner">
          {items.map((item, index) => (
            <ItemCard item={item} key={item.item_id} editable={false} />
          ))}
        </div>
      </div>
      <div className="ItemsRoomHaveMainDiv">
        <span style={{ display: "flex", justifyContent: "space-between" }}>
          <h2>
            Allot Items
            <img alt="arrow" src={arrow} />
          </h2>
          
        </span>
        <div className="itemsContiner">
          {items1.map((item, index) => (
            <SelectItemsCard items1 = {items1} setItems1={setItems1} item={item} key={item.item_id}  />
          ))}
        </div>
      </div>
      <div className="BtnConatner">
            <button
              className="blueBtn greenBtn"
              onClick={() => {
                handleAllot();
              }}
            >
              {selected ?"Allot":"No Allot"}
            </button>
          
          </div>
    </>
  );
};

export default ItemsRoomHave;
