
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import arrow from "../../CommonIcons/itemArr.svg";
import "./workerPage.css";
import { useApp } from "../../../Context/Context";
import axios from "axios";
import ItemCard from "../HomePage/ItemCard";

const RecieveditemsPage = () => {

  const { API_ENDPOINT,setAnimation ,isCleaner,getWashableAllotmentHistory} =
    useApp();
  const location = useLocation();
  let isRecieve = location.pathname.includes("/receive-items");
  const [items1, setItems1] = useState([]);
  const [worker, setWorker] = useState({});
  // console.log(isRecieve+""+setWorker)
  
  const fetchWorkerAllotedItems = async function () {
    setAnimation(true)
    let userId = JSON.parse(sessionStorage.getItem("user-data")).user.user_id
    try {
        if(!userId){
            console.log("User Not Login")
        }
      const response = await axios.get(
        `${API_ENDPOINT}/worker/get_items_allotted_to_user`,
        {
          headers: {
            "User-id": userId, // Pass the user ID in the request header
          },
        }
      );
      console.log(response.data.allotted_items);
      setItems1(response.data.allotted_items);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        console.log("User not found.");
      } else {
        console.log("An error occurred while fetching the items.", err);
      }
    }
    setAnimation(false)
  };

  useEffect(() => {
    if(isCleaner){
      getWashableAllotmentHistory()
    }else{
    fetchWorkerAllotedItems()
    }
    // eslint-disable-next-line 
  }, []);

  useEffect(()=>{
    if(localStorage.getItem('present-allotments')){
      setItems1(JSON.parse(localStorage.getItem('present-allotments')))
    }

  },[localStorage.getItem('present-allotments')])
 


  return (
    <div className="majorDisplayMainDiv homeItemsMainDiv ">
      {worker && (
        <>
          <span style={{ display: "flex", justifyContent: "space-between" }}>
            <h2>
              Select Items <img alt="arrow" src={arrow} />
            </h2>
            
          </span>
          <div className="selectedItemsContainer cardsContiner ">
            <>
              {items1.map((item) => {
                if(item.quantity !== 0)
                return (
                  <ItemCard
                  item={item} key={item.item_id} editable={false}
                  />
                );
              })}
              {items1.length === 0 && <p>No Items To Show</p>}
            </>
          </div>
        </>
      )}
    </div>
  );
};

export default RecieveditemsPage;
