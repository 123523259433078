import React from 'react'
import arrow from  "../../CommonIcons/itemArr.svg"
import { useNavigate } from 'react-router-dom';
import { useApp } from '../../../Context/Context';

const Profile = () => {
  let {showAlert} = useApp()
  let user = null;
  user = JSON.parse(sessionStorage.getItem("user-data")) &&  JSON.parse(sessionStorage.getItem("user-data")).user;

  const navigate = useNavigate()

  if(!user){
    navigate("/", { replace: true });
  }

  const logoutFunc = function () {
    sessionStorage.removeItem("user-data");
    navigate("/", { replace: true });
    window.location.replace("/");
    showAlert('info',"Loged out !!")
  };

  return (
    <>
    <div className=" majorDisplayMainDiv homeItemsMainDiv ">
        <h2>
          Profile <img alt="arrow" src={arrow} />
        </h2>
        <div className='profileMainContainer'>
          <div className='entryContainer'>
            <p>Full Name -</p>
            <p>{user.name}</p>
          </div>
          <div className='entryContainer'>
            <p>Email Address -</p>
            <p>{user.email}</p>
          </div>
          <div className='entryContainer'>
            <p>Phone -</p>
            <p>{user.phone}</p>
          </div>
          <div className='entryContainer'>
            <p>Role -</p>
            <p>{user.role}</p>
          </div>
          <div className='entryContainer'>
            <p>User Name -</p>
            <p>{user.username}</p>
          </div>
          <div className='entryContainer'>
            <p>Address -</p>
            <p>{user.address}</p>
          </div>
          <div className='entryContainer'>
           <button onClick={()=>{
            logoutFunc()
           }} style={{border:"1px solid red",borderRadius:"6px",padding:"10px 15px"}}>Log out</button>
          </div>
        </div>
      </div>
      
    </>
  )
}

export default Profile
