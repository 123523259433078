import React from 'react'
import { useApp } from '../../../Context/Context';
import { useLocation, useNavigate } from 'react-router-dom';

const RoomCard = ({room}) => {
 

const navigate = useNavigate();
const location = useLocation()



  const navigateToWorker = async function (worker) {
    
    navigate(`${location.pathname}/${worker}`);
  };
  return (
    <>
      <div className='roomCardDIv addCards' onClick={()=>{navigateToWorker(`room-${room.room_id}`)}}>
        {room.room_name}<span>({room.number_of_bed})</span>
      </div>
    </>
  )
}

export default RoomCard
