import React, { useEffect, useState } from "react";
import { useApp } from "../../../Context/Context";
import { useNavigate, useParams } from "react-router-dom";
import SelectItemsCard from "../Worker/SelectItemsCard";
import axios from "axios";
import ItemCard from "../HomePage/ItemCard";

const WorkerMainFlow = () => {
  const { API_ENDPOINT, navigationStack, setHeaderNavigation,getAllotmentHistory,setAnimation ,showAlert} = useApp();
  // const [items, setItems] = useState([]);
  const [apartment, setApartment] = useState("");
  const [roomName, setRoomName] = useState("");
  let { roomId, apartmentName } = useParams();
  roomId = roomId.split("-")[1];
  const [items1, setItems1] = useState([]);
  const [state, setState] = useState({
    damageState: true,
    missingState: false,
    allotState: false,
    washState:false,
    collectState: false,
    checkList: false,
  });
  const navigate = useNavigate();
  const [selected, setSelected] = useState(false);
  const [allotmentItems,setAllotmentItems] = useState([]);

  useEffect(() => {
    const flag = items1.some((item) => item.selected);
    setSelected(flag)
  }, [items1]);

  useEffect(()=>{
    if(apartment){
    filterAllotmentItems()
    }
  },[apartment])

  useEffect(()=>{
    getAllotmentHistory();
  },[])
  

  const filterAllotmentItems = () => {
    const userData = JSON.parse(sessionStorage.getItem("user-data"));
    const userName = userData?.user?.name;
    console.log(userName);
  
    let allotementHistory = {};
  
    if (localStorage.getItem("allotment-history")) {
      const allotmentHistoryData = JSON.parse(localStorage.getItem("allotment-history")).allotment_history;
  
      if (allotmentHistoryData?.[apartment]?.[roomName]?.[userName]) {
        allotementHistory = allotmentHistoryData[apartment][roomName][userName];
      }
    }
  
    setAllotmentItems(allotementHistory);
  };
  

  const fetchItems1 = async (keyword) => {
    setAnimation(true)
    const url = `${API_ENDPOINT}/worker/get_items_allotted_to_room?apartment_id=${apartmentName}&room_id=${roomId}`;
  
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "User-id": JSON.parse(sessionStorage.getItem("user-data")).user_id,
        },
      });
  
      if (!response.ok) {
        const errData = await response.json();
        throw new Error(errData.error);
      }
      const data = await response.json();
      let allotedItems = data.items;
  
      if (allotedItems) {
        let mappingItems = [];
  
        allotedItems.forEach((item) => {
          let item1 = {
            ...item,
            value: 1,
            selected: false,
          };
  
          // Push all items if no keyword is provided, otherwise filter by keyword
          if (item1.quantity_allotted > 0 && (!keyword || item1.item_type === keyword)) {
            mappingItems.push(item1);
          }
        });

        console.log(mappingItems)
  
        setItems1(mappingItems);
      }
  
      setApartment(data.apartment);
      setRoomName(data.room);
    } catch (err) {
      console.log(err);
    }
    setAnimation(false)
  };
  

  const fetchWorkerAllotedItems = async function () {
    let userId = JSON.parse(sessionStorage.getItem("user-data")).user.user_id;
    try {
      if (!userId) {
        console.log("User Not Login");
      }
      const response = await axios.get(
        `${API_ENDPOINT}/worker/get_items_allotted_to_user`,
        {
          headers: {
            "User-id": userId, // Pass the user ID in the request header
          },
        }
      );
      console.log(response.data.allotted_items);
      let allotedItems = response.data.allotted_items;
      // setItems(data.items);
      if (allotedItems) {
        let mappingItems = [];

        allotedItems.forEach((item) => {
          let item1 = {
            ...item,
            value: 0,
            selected: false,
          };
          if (item1.quantity > 0) {
            mappingItems.push(item1);
          }
        });
        console.log(mappingItems);
        setItems1(mappingItems);
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        console.log("User not found.");
      } else {
        console.log("An error occurred while fetching the items.", err);
      }
    }
  };

  const markDamage = async () => {
    // Replace with actual user ID
    let st = { ...state, missingState: true };
    let selectedItems = items1
      .filter((item) => item.selected === true)
      .map((item) => ({
        item_id: item.item_id,
        quantity: Number(item.value),
        object: "Damaged",
      }));

    if(selectedItems.length === 0){
      await fetchItems1()
      setState(st);
      return;
    }

    const payload = {
      apartment_id: Number(apartmentName),
      room_id: Number(roomId),
      items: selectedItems,
    };
    let userId = JSON.parse(sessionStorage.getItem("user-data")).user.user_id;

    // Data to be sent in the body of the request

    try {
      // Making the POST request
      const response = await axios.post(
        `${API_ENDPOINT}/worker/collect_damaged_missing_items`,
        payload,
        {
          headers: {
            "User-Id": userId,
          },
        }
      );

      // Handle the response
      console.log("Response:", response.data);
      await fetchItems1()
    } catch (error) {
      // Handle errors
      console.error(
        "Error reporting items:",
        error.response ? error.response.data : error.message
      );
    }

    console.log(st);
    setState(st);
  };

  const markMissing = async () => {
     // Replace with actual user ID
     let st = { ...state, allotState: true };
     let selectedItems = items1
       .filter((item) => item.selected === true)
       .map((item) => ({
         item_id: item.item_id,
         quantity: Number(item.value),
         object: "Missing",
       }));

       if(selectedItems.length === 0){
        await fetchItems1()
        setState(st);
        return;
      }
 
     const payload = {
       apartment_id: Number(apartmentName),
       room_id: Number(roomId),
       items: selectedItems,
     };
     let userId = JSON.parse(sessionStorage.getItem("user-data")).user.user_id;

     try {
       // Making the POST request
       const response = await axios.post(
         `${API_ENDPOINT}/worker/collect_damaged_missing_items`,
         payload,
         {
           headers: {
             "User-Id": userId,
           },
         }
       );
 
       // Handle the response
       console.log("Response:", response.data);
       await fetchItems1()
     } catch (error) {
       // Handle errors
       console.error(
         "Error reporting items:",
         error.response ? error.response.data : error.message
       );
     }
    console.log(st);
    setState(st);
  };

  const fetchPermanentItems = async function (){
    try {
      const response = await axios.get(`${API_ENDPOINT}/worker/get_permanent_items_allotted_to_room`, {
        headers: {
          'User-id': JSON.parse(sessionStorage.getItem("user-data")).user.user_id,
        },
        params: {
          apartment_id: apartmentName,
          room_id: roomId,
        },
      });
  
      console.log('Response:', response.data);
    } catch (error) {
      console.log(error)
    }
  }


  const handleCollect = async () => {
    let st = { ...state, checkList: true };
    let selectedItems = items1
      .filter((item) => item.selected === true)
      .map((item) => ({
        item_id: item.item_id,
        quantity: Number(item.value),
      }));
      if(selectedItems.length === 0){
        await  fetchPermanentItems();
        setState(st);
        return;
      }

    const payload = {
      apartment_id: Number(apartmentName),
      room_id: Number(roomId),
      items: selectedItems,
    };
    let userId = JSON.parse(sessionStorage.getItem("user-data")).user.user_id;

    try {
      const response = await axios.post(
        `${API_ENDPOINT}/worker/collect_back_item`,
        payload,
        {
          headers: {
            "User-id": userId,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Success:", response.data);
      if (response.status === 200) {
        // await fetchItems1();
      }
      fetchItems1();
    } catch (error) {
      console.log("Error allotting items to worker:", error);
      // Add any error handling or UI updates here
    }

    setState(st);
  };

  const handleAllot = async () => {
    let st = { ...state, washState: true };
    let selectedItems = items1
      .filter((item) => item.selected === true)
      .map((item) => ({
        item_id: item.item_id,
        quantity: Number(item.value),
      }));
    
    let userId = JSON.parse(sessionStorage.getItem("user-data")).user.user_id;
    try {
      if(allotmentItems.length > 0){
      allotmentItems.forEach(async(item)=>{
        const response = await axios.post(
          `${API_ENDPOINT}/worker/push_allotment_history_to_room_item_allotment`,
          {
            "allotment_id":`${item.allotment_id}`
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Success:", response.data);
      })}
     
      if(allotmentItems.length > 0)showAlert("success","Items Alloted Successfully !!")
      fetchItems1("Washable");
    } catch (error) {
      console.log("Error allotting items to worker:", error);
      
    }
    fetchItems1("Washable");
    setState(st);
  };


  const handleWash = async () => {
    let st = { ...state, collectState: true };
    let selectedItems = items1
      .filter((item) => item.selected === true)
      .map((item) => ({
        item_id: item.item_id,
        quantity: Number(item.value),
      }));
      if(selectedItems.length === 0){
        await fetchItems1("Regular");
        setState(st);
        return;
      }

    const payload = {
      apartment_id: Number(apartmentName),
      room_id: Number(roomId),
      items: selectedItems,
    };
    let userId = JSON.parse(sessionStorage.getItem("user-data")).user.user_id;
    try {
      const response = await axios.post(
        `${API_ENDPOINT}/worker/allot_washable_items_to_cleaner`,
        payload,
        {
          headers: {
            "User-id": userId,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Success:", response.data);
      if (response.status === 200) {
        // await fetchItems1();
      }
      fetchItems1("Regular");
    } catch (error) {
      console.log("Error allotting items to worker:", error);
      // Add any error handling or UI updates here
    }

    setState(st);
  };

  const handleSubmit = async () => {
    navigate(`/home/apartments/${apartmentName}`)
  };

 


  

  useEffect(() => {
    fetchItems1();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="WorkerMainFlowMainDiv">
        <span style={{ display: "flex", justifyContent: "space-between" }}>
          <h2> </h2>
          <div className="workerHeaderInfo">
            <ul>
              <li>
                Apartment Name - <span>{apartment}</span>
              </li>
              <li>
                Room Name - <span>{roomName}</span>
              </li>
            </ul>
          </div>
        </span>
        <div className="flowContainer">
          <div className={`flowss ${state.damageState && "active-state"} `}>
            {state.damageState && <div className="activeCircle "></div>}
            Damage Items
          </div>
          <div className={`flowss ${state.missingState && "active-state"} `}>
            {state.missingState && <div className="activeCircle "></div>}
            MissingItems
          </div>
          <div className={`flowss ${state.allotState && "active-state"} `}>
            {state.allotState && <div className="activeCircle "></div>}Allot
            Items
          </div>
          <div className={`flowss ${state.washState && "active-state"} `}>
            {state.washState && <div className="activeCircle "></div>}Wash Items
          </div>
          <div className={`flowss ${state.collectState && "active-state"} `}>
            {state.collectState && <div className="activeCircle "></div>}Collect
            Items
          </div>
          <div className={`flowss ${state.checkList && "active-state"} `}>
            {state.checkList && <div className="activeCircle "></div>}Checklist
          </div>
        </div>
        <div className="itemsContiner">
          {
            (state.missingState &&
            state.allotState &&
            !state.washState) ?
          (allotmentItems.length > 0 ? allotmentItems.map((item) => {
            return (
              <ItemCard
              editable={false}
                item={item}
                key={item.item_id}
              />
            );
          }):
          "No Items To Allot"):(items1.map((item) => {
            return (
              <SelectItemsCard
                items1={items1}
                setItems1={setItems1}
                item={item}
                key={item.item_id}
              />
            );
          }))}
        </div>
        <div className="BtnConatiner">
          {!state.missingState && (
            <button
              className="redBtn"
              onClick={() => {
                markDamage();
              }}
            >
              {selected ?"Mark Damage":"No Damage"}
            </button>
          )}
          {state.missingState && !state.allotState && (
            <button
              className="redBtn"
              onClick={() => {
                markMissing();
              }}
            >
               {selected ?"Mark Missing":"No Missing"}
            </button>
          )}
          {state.missingState &&
            state.allotState &&
            !state.washState && (
              <button
                className="blueBtn greenBtn"
                onClick={() => {
                  handleAllot();
                }}
              >
               { "Done"}
                {/* {selected ?"Allot":"No Allot"} */}
              </button>
            )}
            {state.missingState &&
            state.allotState &&
            state.washState &&
            !state.collectState && (
              <button
                className="blueBtn greenBtn"
                onClick={() => {
                  handleWash();
                }}
              >
                {selected ?"Mark Wash":"No Wash"}
              </button>
            )}
          {state.missingState &&
            state.allotState && state.washState&&
            state.collectState && !state.checkList && (
            <button
              className="blueBtn"
              onClick={() => {
                handleCollect();
              }}
            >
              {selected ?"Collect":"No Collect"}
            </button>
          )}
          
          {state.missingState &&
            state.collectState &&
            state.allotState &&
            state.washState&&
            state.checkList && (
              <button
                className="blueBtn"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Check
              </button>
            )}
        </div>
      </div>
    </>
  );
};

export default WorkerMainFlow;
